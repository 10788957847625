import * as React from "react";
// @ts-ignore
import projectPic1 from '../../../static/images/mka.png';
// @ts-ignore
import projectPic2 from '../../../static/images/stad1.jpg';
// @ts-ignore
import projectPic9 from '../../../static/images/b2b.png';
// @ts-ignore
import projectPic3 from '../../../static/images/eis.png';
import { Carousel } from 'react-responsive-carousel';

export default class ProjectsSection extends React.Component {
    render() {
        return (
            <section id="projects" className="projects container">
                <h2 className="title">Наши проекты</h2>
                <div className="project-list">
                    <Carousel autoPlay={true} swipeable={true} emulateTouch={true} showStatus={false} infiniteLoop={true}>
                        <div className="item">
                            <figure>
                                <img src={projectPic9}
                                     alt="Портал Б2Б"/>
                            </figure>
                            <div className="info">
                                <h3>Б2Б платформа</h3>
                                <p>Продуманный функционал для автоматизации оптовых продаж.</p>
                                <p>
                                    Полный процесс покупки, включая учет остатков, скидки и акции, гибкую настройку характеристик товаров, интеграцию с 1С и модуль аналитики.
                                </p>
                                <p>Оптимизация и настройка под ваши бизнес-процессы
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <figure>
                                <img src={projectPic1}
                                     alt="Автоматизация внутренних бизнес-процессов Москомархитектуры"/>
                            </figure>
                            <div className="info">
                                <h3>Автоматизация внутренних бизнес-процессов Москомархитектуры </h3>
                                <p>Для различных подразделений Москомархитектуры разработаны подсистемы,
                                    позволяющие
                                    автоматизировать деятельность этих подразделений.</p>
                                <p>Реализовано взаимодействие с имеющимися и отлаженными процессами, интеграции со сторонними ресурсами для обмена данными и актуализации информации по
                                    рассматриваемым вопросам. </p>
                            </div>
                        </div>
                        <div className="item">
                            <figure>
                                <img src={projectPic2}
                                     alt="Портал взаимодействия государственных служб для обеспечения безопасности при проведении крупных спортивных мероприятий"/>
                            </figure>
                            <div className="info">
                                <h3>Портал взаимодействия государственных служб для обеспечения безопасности при
                                    проведении
                                    крупных спортивных мероприятий </h3>
                                <p>Портал, обеспечивающий оперативный контроль за текущими событиями,
                                    постановку заданий и контроль их выполнения, обмен информацией между различными
                                    ведомствами.</p>
                                <p>Реализована интеграция с региональными центрами для аккумулирования и сопоставления
                                    актуальных данных, обеспечена отказоустойчивость работы портала, разработан сквозной
                                    поиск по всей имеющейся информации. </p>
                            </div>
                        </div>
                        <div className="item">
                            <figure>
                                <img src={projectPic3} alt="Система учета коммунальных ресурсов "/>
                            </figure>
                            <div className="info">
                                <h3>Система учета коммунальных ресурсов </h3>
                                <p>Автоматизация процесса снятия и передачи показаний приборов учета: холодное и горячее
                                    водоснабжение, газ, электроэнергия.</p>
                                <p>Поддерживается интеграция с управляющими компаниями, релизована рассылка
                                    пользователям
                                    системы отчетов о потреблении. </p>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </section>
        )
    }
}
